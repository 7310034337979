

.title{
    text-align: center;
    font-size: 48px;
    margin-top: 85px;
}

#title{
    font-size: 38px;
    margin-top: 85px;
    color: black;
}


.programs{
   margin: 80px auto;
   width:90%; 
   display:flex;
   align-items: center;
   justify-content: space-between;

}

.program img{
    width:100%;
    border-radius: 10px;
    display:block;
}

.program{
    flex-basis: 31%;
    position: relative;
}


.caption{
    border-radius: 10px;
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgba(0, 0, 2, 0.514);
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    color:#fff;
    opacity:0;
    padding-top: 70%;
    transition: 0.4s; 
}

.caption p{
    font-size: 28px;
    font-weight: 500;
}

.caption img{
    width:60px;
    margin-bottom: 10px;
}

.program:hover .caption{
    opacity: 1;
    padding-top: 0;
}



@media (max-width:750px) {
    .title{
        font-size: 20px;
        margin-top:0px;
    }

    .programs{
        display:flex;
        gap:10px;
        margin: 20px auto;
     
     }
     
     .caption p{
        font-size: 15px;
        font-weight: 500;
    }
    #title{
        font-size: 18px;
        margin-top: 0px;
        color: black;
    }
    
}