.about{
    margin:100px auto;
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    background:url(../../Assets/frontend_assets/Background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* opacity:0.5; */
}

.about-left{
    flex-basis: 48%;
    position: relative;
}

.about-right{
    flex-basis: 48%;
    padding-top: 50px;
}

.about-img{
    width:100%;
    border-radius: 10px;
}

.play-icon{
    width:60px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}   

.about h3{
    font-weight: 600;
    font-size:26px;
    color:#212ea0;
}

.about h2{
    font-size: 35px;
    color:#000f38;
    margin-bottom: 25px;
}

.about p{
    color:#676767;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.5;
}

@media (max-width: 768px) {
   
    .about{
        flex-direction: column;
        background:none;
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: justify;
        margin-top: 0;
    }
    .about-right{
        padding-top: 20px;

    }

    .about h3{
        font-weight: 600;
        font-size:17px;
    }
    
    .about h2{
        font-size: 20px;
        margin-bottom: 25px;
    }
     
    .about p{
        font-size: 14px;
        line-height: 1.5;
    }
  }  