@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Outfit:wght@100..900&display=swap');


*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  font-family: 'outfit';

}

body{
  min-height: 100vh;
  background-color: rgb(241, 241, 241);
  /* background: url(./Assets/frontend_assets/Background_Spices.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
}

a{
  text-decoration: none;
  color: inherit;
}





@keyframes fadeIn {
  0%{
    opacity:0
  }

  100%{
    opacity:1
  }
  
}