.contact{
    margin:80px auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.contact-col{
    flex-basis:48% ;
    color:#676767;
}

.contact-col h3{
    color:#000f38;
    font-weight: 500;
    font-size: 15px;
    display:flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-col h3 img{
    width: 35px;
    margin-left: 10px ;
}

.contact-col p{
    max-width: 450px;
    list-style: 0.3;
}

.contact-col ul li{
    display:flex;
    align-items: center;
    margin: 20px 0;
}

.contact-col ul li img{
    width: 20px;
    margin-right: 10px;
}

.contact form input, .contact form textarea{
    display: block;
    width: 100%;
    background: #ebecfe;
    padding: 15px;
    border: 0;
    margin-bottom:15px ;
    margin-top: 5px;
    resize: none;
}


.contact-col span{
    display:block;
    margin: 20px 0;
}

.btn{
    background: #ffff;
    color: #212121;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border:0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .btn img{
    width:20px;
    margin-left: 10px;
  }
  
  .btn.dark-btn{
    background: #212ea0;
    color:#fff;
  }

  .title{
    text-align: center;
    margin:10px 0 30px;
    color: #212ea0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;

}

.title h2{
    font-size: 32px;
    color:#000f38;
    margin-top:5px;
}

@media (max-width:750px){

    .col-1{
        display: none;
    }
    
    .contact-col{
        flex-basis:100% ;
    }
    .contact{
        margin:30px auto;
    }
    .title{
        margin:0px;
    
    }

    .title p{
        font-size: 14px;
    }

    .title h2{
        font-size: 20px;
    }

    .btn{
        padding: 12px 20px;
        font-size: 12px;
      }

      .btn img{
        width:15px;
        margin-left: 8px;
      }
      
}