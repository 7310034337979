.header {
    height: 34vw;
    margin: 30px auto;
    background-size: cover; /* Makes the image fill the container */
    background-position: center;
    position: relative;
    transition: background-image 0.5s ease-in-out; /* Smooth transition effect */
    border-radius: 10px;
}

.header .header-contents {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5vw;
    max-width: 50%;
    bottom: 10%;
    left: 6vw;
    animation: fadeIn 1s;
}

.header-contents h2 {
    font-weight: 500;
    color: white;
    font-size: max(4.5vw, 22px);
}

.header-contents p {
    color: white;
    font-size: 1vw;
    text-align: justify;
}

.header-contents button {
    border: none;
    color: #747474;
    font-weight: 500;
    padding: 1vw 2.3vw;
    background-color: white;
    font-size: max(1vw, 13px);
    border-radius: 50px;
    cursor: pointer;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media (max-width:1050px) {
    .header{
        height:38vw;
    }

    .header-contents{
        max-width: 45%;
    }

    @media (max-width:750px) {
        
    
        .header-contents{
            max-width: 55%;
        }

        /* .header-contents p{
            display: none;
        } */

        .header-contents button{
            padding:2vw 4vw;
        }

    }

    button{
        display: none;
    }
    .button{
        display: none;
    }

}
