.home{
    width:80%;
    margin: auto;
  
  }


  @media (max-width:750px) {
    .home{
      width:90%;
      margin: auto;
    
    }
  
    
  }