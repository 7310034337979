.footer{
    color: #d9d9d9;
    background-color: #323232;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 8vw;
    padding-top:80px ;
    margin-top: 50px;
}

.footer-content{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap:80px;
}

.footer-content-left, .footer-content-right,.footer-content-center{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}


.footer-content-center iframe{
    width:100%;
    height: 80%;
}

.footer-content-left{
    margin-top: -10px;
}

.footer-content-left p{
    max-width:90%;
}

.footer-content-left img{
    width:180px;
}


.footer-content-left li, .footer-content-right li, .footer-content-center li{
    list-style: none;
    margin-bottom: 10px;
    cursor: pointer;
}

 .footer-content-right h2, .footer-content-center h2{
  color:#fff;
}
.footer-social-icons img{
    width:40px;
    margin-right: 10px;
}

.footer hr{
    width:100%;
    height:2px;
    margin: 20px 0;
    background-color: grey;
    border: none;
}

.footer-social-icons button{
    background:transparent;
    font-size: 16px;
    color: #fdfdfd;
    border: 1px solid rgb(161, 110, 0);
    padding: 10px 30px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.footer-social-icons button:hover{
    background-color: #fff4f26b;
    color: #ffffff;
}

@media (max-width:750px) {


    .footer-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:35px;
    }


    .footer-content-left{
        margin-top: -20px;
    }
     

    .footer-content-left p{
        max-width:100%;
        text-align: justify;
    }

    .footer-copyright{
        font-size: 14px;
    }

    button{
        display: block;
    }


    
}