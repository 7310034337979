.whatsapp-button{
    position:fixed;
    bottom: 20px;
    right:20px;
    background-color: #25d356;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 1000;
    transition: ease-in 0.5s;
}

.whatsapp-button:hover{
    transform: scale(1.1);

}