.explore-menu{
    display:flex;
    flex-direction: column;
    gap:20px;
    max-width: 80%;
    margin: auto;
}

.explore-menu h1{
    color:#262626;
    font-weight: 500;
    text-align: center;
}
.explore-menu p{
text-align: center;
}

.explore-menu .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
}



.explore-menu-text{
    max-width: 60%;
}

.explore-menu-list{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: center;
    margin: 20px 0px;
    overflow-x: scroll;
}

.explore-menu-list::-webkit-scrollbar{
    display:none;
}

.explore-menu-list-item img{
    width:12vw;
    min-width: 80px;
    cursor: pointer;
    border-radius: 50%;
    transition:0.2s;
}


.explore-menu-list-item p{
    margin-top:10px;
    color:#747474;
    font-size: max(1.4vw, 16px);
    cursor: pointer;
}

.explore-menu hr{
    margin: 10px 0;
    height:2px;
    background-color: #e2e2e2;
    border: none;
}

.explore-menu-list-item img.active{
    border: 4px solid tomato;
}


@media (max-width:768px) {
    .explore-menu h1{
        font-size: 22px;
    }

    .explore-menu-text{
        max-width: 100%;
        font-size: 14px;

    }

    .explore-menu-list-item img{
        width:12vw;
    }

    .explore-menu-list{
        gap: 10px;
        margin: 10px 0px;
    }
    .explore-menu{
        max-width: 100%;
    }


}