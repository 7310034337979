.testimonials{
    margin:40px auto;
    padding:0 80px;
    position: relative;


}

.heading{
    text-align: center;
    font-size: 38px;
    color: #212ea0;
    margin-top: 40px;
}


.next-btn, .back-btn{
    position: absolute;
    top:50%;
    right:0;
    transform: translate((-50%));
    padding: 15px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    background: #212ea2;
}

.back-btn{
    left: 0;
    right:auto

}

.slider{
    overflow: hidden;
}


.slider ul{
    display:flex;
    width:200%;
    overflow-x: hidden;
    transition: 0.5s;
    transform: translateX(-15%);
}

.slider ul li{
    list-style: none;
    width:50%;
   padding:20px;
}

.slide{
    box-shadow:0 0 20px rgba(0,0,0,0.05);
    padding:40px;
    border-radius: 10px;
    color:#676767;
    line-height: 1.4;

}

.slide p span{
    font-size: 18px;
    font-weight: 600;
    color: #000a61;
    margin:15px auto;
    padding: 15px auto;
}

.slide img {
    width:80px;
    border-radius: 50%;
    margin-right: 10px;
    border: 4px solid #212ea0;
}

.user-info{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 20px;
    font-size: 15px;
}

.partner-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.8em;
    font-weight: 300;
    margin-top: 20px;
}

.list{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:750px) {
    .testimonials{
        padding: 0 40px;
    }

    .next-btn, .back-btn{
        padding: 10px;
        width: 35px;
    }

    .testimonials ul li{
        padding: 10px;
    }
    .slide{
        padding: 10px;
    }
    .slide img{
        width:50px;
    

    }

    .slide p span{
        font-size: 14px;
    }

    .slider p{
        font-size: 12px;
    }
    .heading{
        font-size: 28px;
        margin-bottom: 15px;
    }
    
    .partner-desc{
        max-width: 85%;
        margin: auto;
        line-height: 1.5em;
        font-size: 16px;
        text-align: justify;
    }
}
