.food-item{
    width:100%;
    margin:auto;
    border-radius:15px;
    box-shadow: 0px 0px 10px #000015ab;
    transition:0.3s;
    animation:fadeIn 1s;
}

.food-item-image{
    width:100%;
    border-radius: 15px 15px 0px 0px;
}

.food-item-info{
    padding:20px;
    cursor: pointer;
}

.food-item-name-rating{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.food-item-name-rating p{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.food-item-name-rating img{
    width:70px;
}

.food-item-desc{
    color:#676767;
    font-size:15.5px;
}

.food-item-price{
    color:tomato;
    font-size: 22px;
    font-weight: 500;
    margin: 10px 0px;
}

.food-item-img-container{
    position:relative;
    cursor: pointer;
}

.food-item-img-container .add{
    width:35px;
    position: absolute;
    bottom:15px;
    right: 15px;
    cursor: pointer;
    border-radius: 50%;
}

.food-item-img-container .food-item-counter{
    position: absolute;
    bottom: 15px;
    right:15px;
    display:flex;
    align-items: center;
    gap:10px;
    padding:6px;
    border-radius: 50px;
    background: white;
}

.food-item-counter img{
 width: 30px; 

}

.food-item-desc{
  text-align: justify;
}